<template>
    <div class="module">
        <van-nav-bar title="电力监控" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="14" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div class="status">
                    运行状态：
                    <van-tag size="medium" type="primary" v-if="item.status">合闸</van-tag>
                    <van-tag size="medium" type="warning" v-else>分闸</van-tag>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'ZPL1通风控制柜',status:1,time:'2021-11-01 12:30'},
                {name:'ZPL2通风控制柜',status:1,time:'2021-11-01 08:30'},
                {name:'YPL4通风控制柜',status:1,time:'2021-11-01 11:30'},
                {name:'YPL6通风控制柜',status:1,time:'2021-11-01 09:30'},
                {name:'备用回路5',status:1,time:'2021-11-01 12:30'},
                {name:'备用回路6',status:1,time:'2021-11-01 02:20'},
                {name:'养护机具',status:1,time:'2021-11-01 12:30'},
                {name:'厂房照明',status:1,time:'2021-11-02 12:00'},
                {name:'配电室内照明',status:1,time:'2021-11-02 12:10'},
                {name:'隧道洞口引道照明',status:1,time:'2021-11-02 14:30'},
                {name:'检修插座',status:1,time:'2021-11-02 14:50'},
                {name:'白日基本照明',status:1,time:'2021-11-02 15:30'},
                {name:'应急照明YJY1',status:1,time:'2021-11-02 16:00'},
                {name:'消防电源回路',status:1,time:'2021-11-02 18:10'},
                {name:'监控外场回路',status:1,time:'2021-11-02 19:00'},
                {name:'备用回路7',status:0,time:'2021-11-02 19:10'},
                {name:'备用回路8',status:0,time:'2021-11-02 19:40'},
            ]
        }
    },
    methods:{
        
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>